import { ApiOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyIntegrationListPage = lazy(
  () =>
    import(
      '@pages/dashboard/integration/IntegrationListPage/IntegrationListPage.adapter'
    ),
);

const LazyIntegrationDetailsPage = lazy(
  () =>
    import(
      '@pages/dashboard/integration/IntegrationDetailsPage/IntegrationDetailsPage.adapter'
    ),
);

const LazyIntegrationCreatePage = lazy(
  () =>
    import(
      '@pages/dashboard/integration/IntegrationCreatePage/IntegrationCreatePage.adapter'
    ),
);

const IntegrationRoute: Route = {
  children: [
    {
      component: LazyIntegrationDetailsPage,
      id: 'integration-details',
      path: PATH.Integration.Detail(':integrationId'),
      requiredPermission: OrganisationPermission.IntegrationDetail,
    },
    {
      component: LazyIntegrationCreatePage,
      id: 'integration-create',
      path: PATH.Integration.Create,
      requiredPermission: OrganisationPermission.IntegrationCreate,
    },
  ],
  component: LazyIntegrationListPage,
  hideInSidebar: false,
  iconComponent: ApiOutlined,
  id: 'integrations',
  path: PATH.Integration.List,
  requiredPermission: OrganisationPermission.IntegrationList,
  serverSupportVerifier: (serverConfig) =>
    Boolean(
      serverConfig?.openidProvider && !serverConfig.openidProvider.disabled,
    ),
  title: 'route.integrations.title',
  withTabs: true,
};

export default IntegrationRoute;
