import appConfig from '@appconf/config';
import { RoutesConfiguration } from '@models/types/Routes';
import credentialsRoute from '@router/configuration/Credentials';
import schemasRoute from '@router/configuration/CredentialSchemas';
import dashboardRoute from '@router/configuration/Dashboard';
import didRoute from '@router/configuration/Did';
import integrationRoute from '@router/configuration/Integration';
import keysRoute from '@router/configuration/Key';
import mockSettingsRoute from '@router/configuration/Mock';
import profileRoute from '@router/configuration/Profile';
import proofRequestsRoute from '@router/configuration/ProofRequests';
import proofSchemasRoute from '@router/configuration/ProofSchemas';
import rootRoute from '@router/configuration/Root';
import trustManagementRoute from '@router/configuration/TrustManagement';
import unknownRoute from '@router/configuration/Unknown';

const additionalRoutes = appConfig.mocksEnabled ? [mockSettingsRoute] : [];

const routesConfig: RoutesConfiguration = [
  {
    routes: [
      dashboardRoute,
      credentialsRoute,
      schemasRoute,
      proofRequestsRoute,
      proofSchemasRoute,
      ...additionalRoutes,
    ],
    title: 'sideMenu.applications',
  },
  {
    routes: [didRoute, keysRoute, trustManagementRoute, integrationRoute],
    title: 'sideMenu.administration',
  },
  {
    routes: [profileRoute, rootRoute],
    title: '',
  },
  {
    routes: [unknownRoute],
  },
];

export default routesConfig;
